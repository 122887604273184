<template>
    <div class="main_con">
        <el-card id="table"  v-loading="loading">
        <template>
            <el-carousel trigger="click" height="750px" :autoplay=false arrow="always">
                <el-carousel-item 
                v-for="item in banners"
                >
                    <el-image 
                    style="width: 100%; height: 750px"
                    :src="item.url"
                    fit="fill">
                    </el-image>
                </el-carousel-item>
            </el-carousel>
        </template>
        
        </el-card>
    </div>
</template>

<script>
import { get_train_record } from '@/api/train';
import * as echarts from 'echarts';
export default {
    name: "PatrolSchedule",
    components: {},
    data() {
        const urls = [];
        for (let i = 1; i <= 17; i++) {  // 假设你想生成 1 到 10 的 URL
            const paddedNumber = String(i).padStart(2, '0'); // 确保数字是两位数，例如 '01', '02'
            urls.push({ url: require(`../../../../static/operationDangerous/数据中心IT运维红线V1.0_${paddedNumber}.png`) });
        }
        return {
            banners : urls
        };
    },
    mounted() {

    },
    created() {
    },
    methods: {

    },
};
</script>
<style scoped>
.main_con {
    text-align: center;
    padding: 16px;
}

.progressChart {
    width: 95%;
    height: 420px;
    border: 1px solid #aaa;
    margin: 0 auto;
    margin-top: 30px;
}

.success_content {
    background-color: #daffa2;
}
.table{
    background: grey;
}
.graph {
    margin-top: 16px;
}

::v-deep .el-carousel__arrow--left{
  font-size: 25px;
  background-color: black;
}

::v-deep .el-carousel__arrow--right{
  font-size: 25px;
  background-color: black;
}


.right {
    display: flex;

    p {
        font-size: 14px;
        line-height: 14px;
        color: #333;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .line {
            display: inline-block;
            width: 100%;
            height: 3px;
            background: #1890ff;
            border-radius: 2px;
            margin-top: 8px;
        }
    }
    .line {
        display: inline-block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        border-radius: 2px;
        margin-top: 8px;
    }
}

</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main_con" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { id: "table" }
        },
        [
          [
            _c(
              "el-carousel",
              {
                attrs: {
                  trigger: "click",
                  height: "750px",
                  autoplay: false,
                  arrow: "always"
                }
              },
              _vm._l(_vm.banners, function(item) {
                return _c(
                  "el-carousel-item",
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "750px" },
                      attrs: { src: item.url, fit: "fill" }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }